import IMenuItem from "../types/menuItem";
import {RouteObject} from "react-router-dom";

export const isDesktop = () => window.innerWidth >= 992;

export const isIE = () => /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);

export const breakpoints = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
}//xs: 0, // small sm: 600, // medium md: 900, // large lg: 1200, // extra-large xl: 1536
