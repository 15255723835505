import {useState} from 'react';
// material
import {styled, alpha} from '@mui/material/styles';
import {Input, Slide, Button, IconButton, InputAdornment, ClickAwayListener} from '@mui/material';
// component
import Iconify from '../Iconify';
import {CustomBaseTheme} from "../../theme/types";

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 72;

const SearchbarStyle = styled('div')(({theme}) => ({
    top: 0,
    left: 0,
    zIndex: 99,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    height: APPBAR_MOBILE,
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    padding: theme.spacing(0, 3),
    boxShadow: (theme as CustomBaseTheme).customShadows.z8,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
        height: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.up('lg')]: {
        borderRadius: '15px'
    }
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div>
                {!isOpen && (
                    <IconButton onClick={handleOpen}>
                        <Iconify icon="eva:search-fill" sx={{width: 20, height: 20}}/>
                    </IconButton>
                )}

                <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
                    <SearchbarStyle>
                        <Input
                            autoFocus
                            fullWidth
                            disableUnderline
                            placeholder="Поиск…"
                            startAdornment={
                                <InputAdornment position="start">
                                    <Iconify icon="eva:search-fill" sx={{color: 'text.disabled', width: 20, height: 20}}/>
                                </InputAdornment>
                            }
                            sx={{mr: 1, fontWeight: 'fontWeightBold'}}
                        />
                        <Button variant="contained" onClick={handleClose}>
                            Поиск
                        </Button>
                    </SearchbarStyle>
                </Slide>
            </div>
        </ClickAwayListener>
    );
}
