import {CustomBaseTheme} from "../types";
import {alpha} from "@mui/material/styles";

export default function Paper(theme:CustomBaseTheme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },

      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backdropFilter: 'blur(6px)',
          WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
          '& .arrow':{
            background: `linear-gradient(135deg, ${alpha(theme.palette.background.paper, 0)} 47%, ${theme.palette.background.paper} 47%)`,
          }
        },
      },
      arrow:{
        styleOverrides: {
          background: theme?.palette.grey["400"],
        }
      }
    }
  };
}
