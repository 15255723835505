import Card from './Card';
import Paper from './Paper';
import Input from './Input';
import Lists from "./Lists";
import Button from './Button';
import Tooltip from './Tooltip';
import Backdrop from './Backdrop';
import Typography from './Typography';
import IconButton from "./IconButton";
import CssBaseline from './CssBaseline';
import Autocomplete from './Autocomplete';
import {CustomBaseTheme} from "../types";

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme:CustomBaseTheme) {
  return Object.assign(
      Card(theme),
      Paper(theme),
      Input(theme),
      Lists(theme),
      Button(theme),
      Tooltip(theme),
      Backdrop(theme),
      Typography(theme),
      IconButton(theme),
      CssBaseline(theme),
      Autocomplete(theme),
  );
}
