import {useEffect, useState} from 'react';
// material
import {styled, alpha, useTheme} from '@mui/material/styles';
import {Input, Slide, Button, InputAdornment, ClickAwayListener} from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ButtonBase from '@mui/material/ButtonBase';
// component
import {CustomBaseTheme} from "../../theme/types";
import {breakpoints} from "../../../utils/Info";


const ImageListItemStyle = styled(ImageListItem)(({theme}) => ({
    boxShadow: (theme as CustomBaseTheme).customShadows.z8,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
}));

export default function AdArea() {
    const theme = useTheme();

    const getColumns = (width:number) => {
        if (width < breakpoints.sm) {
            return 3
        } else if (width < breakpoints.md) {
            return 4
        } else if (width < breakpoints.lg) {
            return 5
        } else if (width < breakpoints.xl) {
            return 6
        } else {
            return 6
        }
    }

    const [columns, setColumns] = useState(getColumns(window.innerWidth))
    const updateDimensions = () => {
        setColumns(getColumns(window.innerWidth))
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (//sx={{ width: 500, height: 450 }}
        <ImageList gap={8}
                   cols={columns}
                   // rowHeight={150}
        >
            {itemData.map((item) => (
                <ImageListItemStyle key={item.img}>

                    <img
                        src={`${item.img}`}
                        srcSet={`${item.img}?2x`}
                        alt={item.title}
                        loading="lazy"
                    />
                    <ButtonBase sx={{width:'100%', height:'100%', position:'absolute'}} href={item.url} />
                </ImageListItemStyle>
            ))}
        </ImageList>
    );
}

const itemData = [
    {img:'/fake/ad/1.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/2.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/3.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/4.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/5.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/6.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/7.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/8.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/9.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/10.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/11.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/12.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/13.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/14.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/15.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/16.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/17.gif', url:'https://google.com/', title:'Comp name'},
    {img:'/fake/ad/18.gif', url:'https://google.com/', title:'Comp name'},
]
