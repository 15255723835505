import { computed, makeObservable } from "mobx";
import Store from "../stores";
import IUser from "../types/user";

export default class User implements IUser {

    id: number;
    login: string;
    balance: number;
    actions: Array<string>;

    constructor(private store: Store, data: IUser) {
        this.id = data.id;
        this.login = data.login;
        this.balance = data.balance;
        this.actions = data.actions;

        // @ts-ignore
        makeObservable(this, {store: false});
    }

    // @computed get products() {
    //     return this.store.product.all.filter((it) => it.shopUuid === this.id);
    // }
}
