import React from 'react';
import {useMemo} from 'react';
// material
import {CssBaseline} from '@mui/material';
import {ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider} from '@mui/material/styles';
//
import palette from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, {customShadows} from './shadows';
import {CustomBaseTheme} from "./types";


export default function ThemeProvider({children}: { children?: React.ReactNode }) {
    const themeOptions = useMemo(
        () => ({
            palette,
            shape: {borderRadius: 8},
            typography,
            shadows,
            customShadows,
        }),
        []
    );

    // @ts-ignore
    const theme = createTheme(themeOptions) as CustomBaseTheme;
    theme.components = componentsOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <MUIThemeProvider theme={theme}>
                <CssBaseline/>
                {children}
            </MUIThemeProvider>
        </StyledEngineProvider>
    );
}
