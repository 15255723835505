import React, {FC, useContext, useEffect, lazy, Suspense, useState, useCallback, useRef, useMemo} from 'react';
// import './assets/layout/layout.scss';
import {useAppContext} from "../context";
import {RouteObject, useLocation, useRoutes, Navigate} from "react-router-dom";
import IMenuItem from "../types/menuItem";
import {observer} from "mobx-react-lite";
import {isDesktop} from "../utils/Info";
import {get, KEYS} from "../utils/LocalManager";
import {styled} from "@mui/material";
import Navbar from "./components/dashboard/Navbar";
import Products from "./pages/Products";
import Services from "./pages/Services";
import Forum from "./pages/Forum";

const checkAccess = (accesses:Array<string> = [], access:string|undefined) => (access === undefined || access === '' || accesses.includes(access));

const getRouteObjects = (menu:IMenuItem[], access:Array<string> = []) : RouteObject[] => {
    let routes : RouteObject[] = [];

    menu.forEach( (item) => {
        if (item.to && checkAccess(access, item.access)){
            routes.push({element: item.component, path:item.to, index:item.index, children: item.items ? getRouteObjects(item.items, access) : undefined})
        }else if (item.items && checkAccess(access, item.access)) {
            routes.push(...getRouteObjects(item.items, access));
        }
    })

    return routes as RouteObject[];
}

const getMainObjects = (menu:IMenuItem[], access:Array<string> = []) : IMenuItem[] => {
    let routes : IMenuItem[] = [];

    menu.forEach( (item) => {
        if (item.main && checkAccess(access, item.access)){
            routes.push({to: item.to, label: item.label, color:item.color})
        }
    })

    return routes;
}

const getSubmenuObjects = (menu:IMenuItem[], access:Array<string> = []) : IMenuItem[] => {
    let routes : IMenuItem[] = [];

    menu.forEach( (item) => {
        if (item.label && checkAccess(access, item.access)){
            routes.push({to: item.to, label: item.label})
        }
    })

    return routes;
}

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 72;

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    backgroundImage: 'url("/img/bg.png")',
    backgroundColor: '#ccc',
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: `calc(${APP_BAR_MOBILE}px + 1rem)`,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: `calc(${APP_BAR_DESKTOP}px + 2rem)`,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

document.documentElement.style.fontSize = '14px';

const Main: FC = () => {
    const {store, api} = useAppContext();
    const [open, setOpen] = useState(false);

    const location = useLocation();
    const isSideMenu = location.pathname.includes('admin');

    const storageEventHandler = useCallback((e : any) => {
        if (e.storageArea !== localStorage) return;
        if (e.key === KEYS.TOKEN && !get(KEYS.TOKEN)) store.auth.logout();
        if (e.key === KEYS.COLOR) store.auth.logout();
    }, []);

    // useEffect(() => {
    //     api.refresh().then();
    //     window.addEventListener('storage', storageEventHandler, false);
    // }, []);

console.log('>>>>>> MAIN <<<<<<');
    /**
     * VIEW CONFIG
     */




    /**
     * ROUTES
     */
    // const Products = lazy(() => import('./pages/Products'));
    // const Services = lazy(() => import('./pages/Services'));
    // const Forum = lazy(() => import('./pages/Forum'));

    const Substances = lazy(() => import('./pages/products/Substances'));
    const Equipment = lazy(() => import('./pages/products/Equipment'));
    const Data = lazy(() => import('./pages/products/Data'));

    const Job = lazy(() => import('./pages/services/Job'));
    const Finance = lazy(() => import('./pages/services/Finance'));
    const Security = lazy(() => import('./pages/services/Security'));
    const Tec = lazy(() => import('./pages/services/Tec'));
    const Escort = lazy(() => import('./pages/services/Escort'));

    const menuProducts : IMenuItem[] = [
        { to: '/products', component: <Navigate to="/products/substances" />, access:''},
        { label: 'Вещвества', to: '/products/substances', access:'', component: <Suspense fallback={<h2>Loading...</h2>}><Substances/></Suspense>, index:true},
        { label: 'Оборудование', to: '/products/equipment', access:'', component: <Suspense fallback={<h2>Loading...</h2>}><Equipment/></Suspense>},
        { label: 'Данные', to: '/products/data', access:'', component: <Suspense fallback={<h2>Loading...</h2>}><Data/></Suspense>},
    ];

    const menuServices : IMenuItem[] = [
        { to: '/services', component: <Navigate to="/services/job" />, access:''},
        { label: 'Кадры', to: '/services/job', access:'', component: <Suspense fallback={<h2>Loading...</h2>}><Job/></Suspense>, index:true},
        { label: 'Финансы', to: '/services/finance', access:'', component: <Suspense fallback={<h2>Loading...</h2>}><Finance/></Suspense>},
        { label: 'Охрана', to: '/services/security', access:'', component: <Suspense fallback={<h2>Loading...</h2>}><Security/></Suspense>},
        { label: 'IT', to: '/services/it', access:'', component: <Suspense fallback={<h2>Loading...</h2>}><Tec/></Suspense>},
        { label: 'Эскорт', to: '/services/escort', access:'', component: <Suspense fallback={<h2>Loading...</h2>}><Escort/></Suspense>},
    ];

    const menu : IMenuItem[] = [
        { label: 'Товары', icon: 'pi pi-fw pi-home', to: '/products', component: <Products color={'#EBA82B'} submenu={getSubmenuObjects(menuProducts, [])}/>, access:'', main:true, color:'#EBA82B', items:menuProducts},//#8966F2
        { label: 'Услуги', icon: 'pi pi-fw pi-home', to: '/services', component: <Services color={'#30bcc9'} submenu={getSubmenuObjects(menuServices, [])}/>, access:'', main:true, color:'#30bcc9', items:menuServices },//#EBA82B
        { label: 'Форум', icon: 'pi pi-fw pi-home', to: 'forum', component: <Forum color={'#f63f51'} submenu={getSubmenuObjects(menuProducts, [])}/>, access:'', main:true, color:'#f63f51' },//#c0ca2f//#84E6CB//#30bcc9
        // {
        //     label: 'System', access:1,
        //     items: [
        //         { label: 'Services', icon: 'pi pi-fw pi-server', to: 'services', component: <Systems/>, access:1},
        //         { to: 'services/template', component: <Users/>, access:1 },
        //
        //         //     items: [
        //         //         { to: 'services/', component: <Services/>, access:1 },
        //         //         { label: 'Services', icon: 'pi pi-fw pi-server', to: 'template1', component: <NotFound/>, access:1 },
        //         //         { label: 'Clients', icon: 'pi pi-fw pi-users', to: 'services/template', component: <Users/>, access:1 }
        //         //     ]
        //         // },
        //         { label: 'Clients', icon: 'pi pi-fw pi-users', to: 'clients', component: <Users/>, access:1 }
        //     ]
        // },
        // { to: '404', component: <NotFound />, access:0},
        { to: '/', component: <Navigate to="/products/substances" />, access:'' },
        // { to: '*', component: <Navigate to="404" replace />, access:0 },
    ];

    const routes = useRoutes(getRouteObjects(menu, []));//store.user.access);

    /**
     * RENDER
     */
    return (
        <RootStyle>
            <Navbar onOpenSidebar={() => setOpen(true)} navConfig={getMainObjects(menu)}/>
            {/*<DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />*/}
            <MainStyle>
                {store.loader.isLoading ? 'loading...' : null}
                {routes}
            </MainStyle>
        </RootStyle>
    );
}

export default observer(Main);

// <TopBar isSideMenu={isSideMenu} onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange}
//         mobileTopBarMenuActive={mobileTopBarMenuActive} onMobileTopBarMenuClick={onMobileTopBarMenuClick} />
// <Toast ref={toast} position="top-center" />
