import {action, makeAutoObservable} from "mobx";
import Store from "./";
import {IAuthResponse, IAuthValues} from "../types/auth";
import {KEYS, set, get, remove} from "../utils/LocalManager";

export default class AuthStore {
    private _isAuth : boolean = false;

    constructor(protected store: Store) {
        // @ts-ignore
        makeAutoObservable(this, {store : false});
    }

    public get isAuth():boolean { return this._isAuth; }
    private set isAuth(value: boolean) {
        this._isAuth = value;
    }


    public login = (value: IAuthResponse) => {
        if (value.accessToken && value.accessToken !== ''
            && value.refreshToken && value.refreshToken !== ''){
            set(KEYS.TOKEN, value.accessToken);
            set(KEYS.REFRESH, value.refreshToken);
            this.isAuth = true;
        }else{
            this.logout();
        }
    }

    public logout = () => {
        remove(KEYS.TOKEN);
        remove(KEYS.REFRESH);
        this.isAuth = false;
        // this.store.user.clear();
    }

}
