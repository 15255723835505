import React, {ReactNode} from "react";
// icons
import {Icon, IconifyIcon} from '@iconify/react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Iconify = ({ icon, sx, ...other }:{icon:string|IconifyIcon, sx?:object}) =>
    <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;

export default Iconify;
// export default function Iconify({ icon, sx, ...other }:{icon:string|IconifyIcon, sx:object}) {
//   return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;
// }
