import React, {FC, lazy, Suspense, useState} from "react";
import {useAppContext} from "../../context";
import {observer} from "mobx-react-lite";
import {Box, Button, ListItemIcon, ListItemText, Container} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AdArea from "../components/dashboard/AdArea";
import {Outlet, RouteObject, useRoutes} from "react-router-dom";
import IMenuItem from "../../types/menuItem";
import SubsectionTabs from "../components/SubsectionTabs";

const Products: FC<{color:string, submenu:IMenuItem[]}> = ({color, submenu}) => {


    const {store, api} = useAppContext();
    const [value, setValue] = useState('one');


    return (
        <Container maxWidth={'xl'}>
            <AdArea/>
            <Box sx={{ width: '100%' }}>
                <SubsectionTabs items={submenu} color={color}/>

            </Box>
            <Outlet/>
        </Container>
    )
}

export default observer(Products);

// <Tabs
//     value={value}
//     onChange={handleChange}
//     textColor="secondary"
//     indicatorColor="secondary"
//     aria-label="secondary tabs example"
//     centered
//     variant="scrollable"
//     // scrollButtons="auto"
//     // allowScrollButtonsMobile
// >
//     <Tab value="one" label="Кадры" />
//     <Tab value="two" label="Финансы" />
//     <Tab value="three" label="Охрана" />
//     <Tab value="three" label="IT" />
//     <Tab value="three" label="Эскорт" />
// </Tabs>
