import React, { useContext } from "react";
import Api from "./apis";
import Store from "./stores";

interface ContextType {
    store: Store;
    api: Api;
}

const Context = React.createContext<null | ContextType>(null);

export const useAppContext = () => {
    const context = useContext(Context);
    return context as ContextType;
};

export default Context;
