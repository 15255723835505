import React, { useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton } from '@mui/material';
// components
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import MenuPopover from '../MenuPopover';
import {observer} from "mobx-react-lite";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// ----------------------------------------------------------------------


interface LocationsType {
    value: string;
    label: string;
}

const LOCATIONS : Array<LocationsType> = [
    { value: '001', label: 'Москва' },
    { value: '002', label: 'Санкт-Петербург' },
    { value: '003', label: 'Новосибирск' },
    { value: '004', label: 'Екатеринбург' },
    { value: '005', label: 'Нижний Новгород' },
    { value: '006', label: 'Казань' },
    { value: '007', label: 'Самара' },
    { value: '008', label: 'Челябинск' },
    { value: '009', label: 'Омск' },
    { value: '010', label: 'Ростов-на-Дону' },
    { value: '011', label: 'Уфа' },
    { value: '012', label: 'Красноярск' },
    { value: '013', label: 'Пермь' },
    { value: '014', label: 'Волгоград' },
    { value: '015', label: 'Воронеж' },
    { value: '016', label: 'Саратов' },
    { value: '017', label: 'Краснодар' },
];



// ----------------------------------------------------------------------

const LocationPopover = () => {
    const [value, setValue] = useState<LocationsType>(LOCATIONS[0]);
    const [inputValue, setInputValue] = useState('');

    return (
        <Autocomplete
            id="country-select-demo"
            sx={{ width: '100%', my: 1.5, px: 2.5 }}
            options={LOCATIONS}
            autoHighlight
            disableClearable
            getOptionLabel={(option) => option.label}
            value={value}
            onChange={(event: any, newValue: LocationsType) => {
                setValue(newValue);
            }}
            // renderOption={(props, option) => (
            //     <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            //         {option.label}
            //     </Box>
            // )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size={'small'}
                    variant={'standard'}
                    label="Выбрать город:"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'city-name', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}
export default observer(LocationPopover);

// export default function LocationPopover() {
//     const anchorRef = useRef(null);
//     const [open, setOpen] = useState(false);
//     const [city, setCity] = useState(0);
//
//   const handleOpen = () => {
//     setOpen(true);
//   };
//
//   const handleClose = () => {
//     setOpen(false);
//   };
//
//   const handleItemClick = (id :number) => {
//       setCity(id)
//   }
//
//   return (
//
//     <>
//       <IconButton
//         ref={anchorRef}
//         onClick={handleOpen}
//         sx={{
//           padding: 0,
//           width: 44,
//           height: 44,
//           ...(open && {
//             bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
//           }),
//         }}
//       >
//           {LOCATIONS[city].label}
//       </IconButton>
//
//       <MenuPopover
//         open={open}
//         onClose={handleClose}
//         anchorEl={anchorRef.current}
//         sx={{
//           mt: 1.5,
//           ml: 0.75,
//           width: 180,
//           '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
//         }}
//       >
//           <SimpleBar style={{ maxHeight: 200 }}>
//               <Stack spacing={0.75}>
//                   {LOCATIONS.map((option, id) => (
//                       <MenuItem key={option.value} selected={id === city} onClick={() => handleItemClick(id)}>
//                           {option.label}
//                       </MenuItem>
//                   ))}
//               </Stack>
//           </SimpleBar>
//
//       </MenuPopover>
//     </>
//   );
// }
