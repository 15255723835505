import React, {useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {alpha} from '@mui/material/styles';
import {Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton} from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
// import account from '../../_mock/account';
import {observer} from "mobx-react-lite";
import {useAppContext} from "../../../context";
import LocationPopover from "./LocationPopover";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'Профиль',
        icon: 'eva:person-fill',
        linkTo: '#',
    },
    {
        label: 'Заказы',
        icon: 'eva:orders-fill',
        linkTo: '/',
    },
    {
        label: 'Настройки',
        icon: 'eva:settings-2-fill',
        linkTo: '#',
    },
];

// ----------------------------------------------------------------------

function AccountPopover() {
    const {store, api} = useAppContext();
    const anchorRef = useRef(null);

    const [open, setOpen] = useState<Element | undefined>(undefined);

    const handleOpen = (e: React.SyntheticEvent) => {
        setOpen(e.currentTarget);
    };

    const handleClose = () => {
        setOpen(undefined);
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <Avatar src={'https://i.pravatar.cc/150'} alt="photoURL"/>
            </IconButton>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{my: 1.5, px: 2.5, display: 'flex', justifyContent: 'space-between'}} >
                    <Typography variant="subtitle2" noWrap>
                        {store.user.user?.login}
                    </Typography>
                    <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                        {`#${store.user.user?.id}`}
                    </Typography>
                </Box>

                <LocationPopover/>

                <Divider sx={{borderStyle: 'dashed'}}/>

                <Stack sx={{p: 1}}>
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{borderStyle: 'dashed'}}/>

                <MenuItem onClick={handleClose} sx={{m: 1}}>
                    Выход
                </MenuItem>
            </MenuPopover>
        </>
    );
}

export default observer(AccountPopover);
