export const KEYS = {
    TOKEN   : 'token',
    REFRESH : 'refresh',
    COLOR   : 'colorMode',
    LANGUAGE: 'lang'
}

export const get = (key:string) : string | null =>
    localStorage.getItem(key);

export const set = (key:string, value:string) : void =>
    localStorage.setItem(key, value);

export const remove = (key:string) : void =>
    localStorage.removeItem(key);
