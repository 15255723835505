import axios from 'axios';
import {IAuthResponse} from "../types/auth";
import Store from "../stores";
import {KEYS, set, get, remove} from "../utils/LocalManager";

export const API_URL = 'http://localhost:4000/api';

export default class Api {
    client = axios.create({
        withCredentials: true,
        baseURL: API_URL,
    });

    constructor(private store: Store) {

        this.client.interceptors.request.use(config => {
            store.loader.isLoading = true;
            console.log('>>>> START : ' + store.loader.isLoading);

            if (config.headers) config.headers.Authorization = `Bearer ${get(KEYS.TOKEN)}`;

            return config;
        });

        this.client.interceptors.response.use(config => {store.loader.isLoading = false; return config;},
            async error => {
                const origReq = error.config;

                if (error.response && error.response.status === 401 && error.config && !error.config._isRetry) {
                    origReq._isRetry = true;

                    const refresh = await this.refresh();
                    return refresh ? this.client.request(origReq) : Promise.reject(error);

                }else{
                    if (error.message !== 'Network Error' && (error.response && error.response.status !== 400)){
                        store.auth.logout();
                    }else{
                        console.log('Please check your internet connection')
                    }
                    store.loader.isLoading = false;
                    return Promise.reject(error);
                }

                throw error;
        });

    }

    async refresh() : Promise<boolean> {
        if (!get(KEYS.REFRESH)) return false;

        try {
            const response = await axios.post<IAuthResponse>(`${API_URL}/auth/refresh`, {token: get(KEYS.REFRESH)});
            this.store.auth.login(response.data);
            return true;
        } catch (e : any) {
            console.log(e.response?.data?.message);
            this.store.auth.logout();
            return false;
        } finally {
            this.store.loader.isLoading = false;
        }
    }

}
