import React, {MouseEventHandler} from "react";
import { Link as RouterLink, NavLink } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import {Box, Stack, AppBar, Toolbar, IconButton, Button} from '@mui/material';
import Iconify from '../Iconify';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import Logo from "../Logo";
import MainNavSection from '../MainNavSection';
import {array} from "yup";
import IMenuItem from "../../../types/menuItem";
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 72;
const APPBAR_INDENT = 2;

const RootStyle = styled(AppBar)(({ theme }) => ({
    // boxShadow: 'none',
    // boxShadow: 'inset 7px 7px 13px #8c8c8c, inset -7px -7px 13px #bebebe;',
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: 'rgba(96, 96, 96, 0.97)',
    boxShadow: '7px 7px 14px #525252, -7px -7px 14px #6e6e6e',
    top: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: '15px',
    borderBottomLeftRadius: '15px',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile

    width: `calc(100% - ${APPBAR_INDENT * .5}rem)`,
    right: `${APPBAR_INDENT * .25}rem`,
    left: `${APPBAR_INDENT * .25}rem`,

    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${APPBAR_INDENT * 2}rem)`,//`calc(100% - ${DRAWER_WIDTH + 1}px)`,
        right: `${APPBAR_INDENT}rem`,
        left: `${APPBAR_INDENT}rem`,
        // top: `${APPBAR_INDENT}rem`,
        // borderRadius: '15px',//`${APPBAR_INDENT}rem`,
    },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    height: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        height: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------

export default function DashboardNavbar({ onOpenSidebar, navConfig }:{onOpenSidebar:()=>void, navConfig:Array<IMenuItem>}) {
    const routeTo = () => {

    }
    return (
        <RootStyle>
            <ToolbarStyle>
                <Box sx={{ flex: .1, display: 'flex', alignItems: 'center' }}>
                    <Logo />
                </Box>

                <MainNavSection navConfig={navConfig} sx={{ flex: .8, display: 'flex', justifyContent: 'center' }}/>

                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }} sx={{flex: .1, justifyContent: 'end'}}>
                    <Searchbar />
                    {/*<NotificationsPopover />*/}
                    <AccountPopover />
                </Stack>
            </ToolbarStyle>
        </RootStyle>
    );
}




// <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
//     <Iconify icon="eva:menu-2-fill" />
// </IconButton>






// <NavLink aria-label={item.label} onKeyDown={onKeyDown} role="menuitem" className="p-menuitem-link p-ripple"
//          to={item.to}
//          onClick={(e) => onMenuItemClick(e, item)} target={item.target}>

// <Box sx={{ flex: .8, display: 'flex', justifyContent: 'center' }} >
//     <NavLink to="dashboard">
//         <Button variant="contained" onClick={routeTo}>
//             ТОВАРЫ
//         </Button>
//     </NavLink>
// </Box>
