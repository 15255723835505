import * as React from 'react';
import {alpha, styled} from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import IMenuItem from "../../types/menuItem";
import {matchPath, useLocation, useNavigate} from "react-router-dom";
import {breakpoints, isDesktop} from "../../utils/Info";
import {useEffect, useState} from "react";


interface StyledTabsProps {
    children?: React.ReactNode;
    value: string;
    onChange: (event: React.SyntheticEvent, newValue: string) => void;
    color: string,
    centered: boolean,
    variant: "standard" | "scrollable" | "fullWidth" | undefined,
}

const StyledTabs = styled((props: StyledTabsProps) => (
    <TabList
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))(({ theme, color }) => ({
    // height: '4rem',
    overflow: 'visible',
    '& .MuiTabs-scroller': {
        padding: '2rem 16px',
        margin: '0 -16px',
        [theme.breakpoints.up('sm')]: {
            margin: '0 -2rem',
            padding: '2rem',
        }
    },
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        top: '29px',
        height: '46px',
    },
    '& .MuiTabs-indicatorSpan': {
        // maxWidth: 40,
        borderRadius: theme.shape.borderRadius,
        width: 'calc(100% - 2px)',
        backgroundColor: color,
        boxShadow: `0 0 5px ${color},
                0 0 15px ${color},
                0 0 30px ${color}`,
    },
}));

interface StyledTabProps {
    label: any;
    color:string;
    value:string|undefined;
}

const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme, color }) => ({
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    display: 'inline-block',
    border: 'none',
    background: 'none',
    padding: '20px 55px',
    margin: '0 5px',
    overflow: 'visible',

    '&:hover,&.Mui-selected' : {
        backgroundColor: 'transparent',
        '.MuiBox-root': {

            background: alpha(theme.palette.common.white, .35),
            letterSpacing: '3px',
            '&::before':{
                transform: 'skewX(40deg) translateX(200%)',
            }
        }
    },
    '&.Mui-selected .MuiBox-root' : {
        color: theme.palette.grey['300'],
        background: alpha(theme.palette.common.white, .05),
    },
    '.MuiBox-root' : {
        ...theme.typography.subtitle2,
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#686868',//alpha(theme.palette.common.white, .55),
        boxShadow: '0 7px 17px rgba(0, 0, 0, .2)',
        borderTop: '1px solid rgba(255, 255, 255, .1)',
        borderBottom: '1px solid rgba(255, 255, 255, .1)',
        borderRadius: theme.shape.borderRadius,
        color: color,
        // color: theme.palette.text.secondary,
        zIndex: 1,
        // fontWeight: 400,
        letterSpacing: '1px',
        textDecoration: 'none',
        overflow: 'hidden',
        textTransform: 'uppercase',
        transition: 'all .3s ease-in-out',
        // backdropFilter: 'blur(15px)',
        // WebkitBackdropFilter: 'blur(15px)',

        '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '50%',
            height: '100%',
            background: 'linear-gradient(to left, rgba(255, 255, 255, .15), transparent)',
            transform: 'skewX(40deg) translateX(0)',
            transition: 'all .5s ease-out',
        }
    },
    // textTransform: 'none',
    // fontWeight: theme.typography.fontWeightRegular,
    // fontSize: theme.typography.pxToRem(15),
    // marginRight: theme.spacing(1),
    // color: 'rgba(255, 255, 255, 0.7)',
    // '&.Mui-selected': {
    //     color: color,
    // },
    // '&.Mui-focusVisible': {
    //     backgroundColor: 'rgba(100, 95, 228, 0.32)',
    // },
}));

export default function SubsectionTabs({ items, color, ...other }:{items:Array<IMenuItem>, color:string, [x:string]: any}) {
    const { pathname, state, key } = useLocation();
    let navigate = useNavigate();

    const match = (path:string) => (path ? !!matchPath({ path, end: false }, pathname) : false);

    const [value, setValue] = React.useState<string>(items[0].to?items[0].to:'job');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        navigate(newValue);
        // setValue(newValue);
    };

    const [desktop, setDesktop] = useState<boolean>(breakpoints.sm <= window.innerWidth)
    const updateDimensions = () => {
        setDesktop( breakpoints.sm <= window.innerWidth );
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <TabContext value={pathname}>
                <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                    color={color}
                    centered={desktop}
                    variant={desktop ? 'fullWidth' : "scrollable"}
                >
                    {items.map((item:IMenuItem) => (
                        <StyledTab key={item.to} label={<Box>{item.label}</Box>} color={color} value={item.to}/>
                    ))}
                </StyledTabs>
            </TabContext>
        </Box>
    );
}
