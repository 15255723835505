import IMenuItem from "../../types/menuItem";
import {alpha, styled, useTheme} from "@mui/material/styles";
import React, {useState} from "react";
import {NavLink as RouterLink} from "react-router-dom";
import {Box, Button, ListItemIcon, ListItemText} from "@mui/material";


const GlassBtnStyle = styled((props) => <Button {...props} />)( ({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    display: 'inline-block',
    border: 'none',
    background: 'none',
    padding: '20px 55px',

    '&::before,::after': {
        content: '""',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '30px',
        height: '5px',
        borderRadius: theme.shape.borderRadius,

        transition: 'all .4s ease-in-out',
        transitionDelay: '0s',
    },
    '&::before': { bottom: '-2px' },
    '&::after': { top: '-2px' },

    '&:hover,&.active' : {
        backgroundColor: 'transparent',
        '&::before,&::after':{
            height: '70%',
            width: '100%',
            transitionDelay: '.3s',
        },
        '&::before': { bottom: 0 },
        '&::after': { top: 0 },
        '.MuiBox-root': {
            color: theme.palette.grey['300'],
            letterSpacing: '3px',
            '&::before':{
                transform: 'skewX(40deg) translateX(200%)',
            }
        }
    },

})) as React.JSXElementConstructor<React.ComponentProps<any>>;

const GlassTextStyle = styled( (props) => <Box {...props}/>)( ({theme}) => ({
    ...theme.typography.subtitle2,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: alpha(theme.palette.common.white, .05),
    boxShadow: '0 15px 35px rgba(0, 0, 0, .2)',
    borderTop: '1px solid rgba(255, 255, 255, .1)',
    borderBottom: '1px solid rgba(255, 255, 255, .1)',
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    // color: theme.palette.text.secondary,
    zIndex: 1,
    // fontWeight: 400,
    letterSpacing: '1px',
    textDecoration: 'none',
    overflow: 'hidden',
    textTransform: 'uppercase',
    transition: 'all .3s ease-in-out',
    backdropFilter: 'blur(15px)',
    WebkitBackdropFilter: 'blur(15px)',

    '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '50%',
        height: '100%',
        background: 'linear-gradient(to left, rgba(255, 255, 255, .15), transparent)',
        transform: 'skewX(40deg) translateX(0)',
        transition: 'all .5s ease-out',
    }
})) as React.JSXElementConstructor<React.ComponentProps<any>>;


// const ListItemIconStyle = styled(ListItemIcon)({
//     width: 22,
//     height: 22,
//     color: 'inherit',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// }) as React.JSXElementConstructor<React.ComponentProps<any>>;

export default function GlassButton({ item, sx, ...other}:{item:IMenuItem, sx?:any, [x:string]: any}) {//, active, ...other }:{item:IMenuItem, active:(path:string)=>boolean, [x:string]: any}) {
    // const theme = useTheme();

    const { label, to, icon, info, items } = item;

    // const isActiveRoot = to ? active(to) : false;
    //
    // const [open, setOpen] = useState(isActiveRoot);
    //
    // const handleOpen = () => {
    //     setOpen((prev) => !prev);
    // };

    const colorStyle = item.color ? {
        '&::before,::after':{
            background: item.color,
            boxShadow: `0 0 5px ${item.color},
                0 0 15px ${item.color},
                0 0 30px ${item.color}`,
                // 0 0 60px ${item.color}`,
        },
        // color: 'primary.main',
        // fontWeight: 'fontWeightMedium',
        // bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        ...sx
    } : sx;

    return (

            <GlassBtnStyle
                component={RouterLink}
                to={to}
                sx={{
                    ...colorStyle,
                }}
                disableRipple={true}
                other
            >
                <GlassTextStyle>
                    {label}
                </GlassTextStyle>
            </GlassBtnStyle>
    );
}
