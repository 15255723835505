import React from 'react';
import {createRoot} from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'

import * as serviceWorker from './serviceWorker';
// import reportWebVitals from './reportWebVitals';

import Store from "./stores";
import Api from "./apis";
import Context from './context';
import ScrollToTop from "./utils/ScrollToTop";
import Main from './views/Main';
import ThemeProvider from './views/theme';

const root = createRoot( document.getElementById('root') as HTMLElement );
const store = new Store();
const api = new Api(store);

root.render(

      <Context.Provider value={{ store, api }}>
          <BrowserRouter>
              <ThemeProvider>
                  <ScrollToTop />
                  <Main />
              </ThemeProvider>
          </BrowserRouter>
      </Context.Provider>

);
serviceWorker.unregister();
// <React.StrictMode>
  {/*</React.StrictMode>*/}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
