import {
    action,
    computed, makeAutoObservable,
    makeObservable,
    observable,
    ObservableMap,
} from "mobx";
import User from "../models/user";
import IUser from "../types/user";
import Store from "./index";

export default class UserStore {
    private _user : User | undefined;

    constructor(private store: Store) {
        // @ts-ignore
        makeAutoObservable(this, {store : false});

        this.load = {id:434237, login:"Jimmy", balance: 3439, actions: []};
    }

    set load(user: IUser) {
        this._user = new User(this.store, user);
    }

    get access() {
        return this._user?.actions;
    }

    get user() {
        return this._user;
    }

    clear() {

    }
}
