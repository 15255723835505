import { makeAutoObservable, observable } from "mobx";
import Store from "./index";

export default class LoaderStore {

    private _loads : number = 0;

    constructor(private store: Store) {
        // @ts-ignore
        makeAutoObservable(this, {store : false});
    }

    get isLoading() : boolean {
        return this._loads > 0;
    }

    set isLoading(value:boolean) {
        this._loads += value ? 1 : -1;
        if (this._loads < 0) this._loads = 0;
    }
}
