import {CustomBaseTheme} from "../types";

export default function Autocomplete(theme:CustomBaseTheme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.z20
        }
      }
    }
  };
}
