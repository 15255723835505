import React from 'react';
import {matchPath, useLocation } from 'react-router-dom';
// material
import { Box, } from '@mui/material';
import Stack from '@mui/material/Stack';
//
import IMenuItem from "../../types/menuItem";
import GlassButton from "./GlassButton";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------


export default function MainNavSection({ navConfig, ...other }:{navConfig:Array<IMenuItem>, [x:string]: any}) {
  // const { pathname } = useLocation();

  // const match = (path:string) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    // <Box {...other}>
    <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5, md: 4 }} {...other}>
        {navConfig.map((item:IMenuItem) => (
          <GlassButton key={item.label} item={item}/>
        ))}
    </Stack>
  );
}
// active={match}
